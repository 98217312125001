<template>
    <div id="app">
        <div>
            <div class="wrapper">
                <div class="fanclash-logo-container">
                    <img :src="fanclashLogo" alt="" class="fanclash-logo">
                </div>
                <div class="top-bar-container">
                    <div class="vodacom-logo-container">
                        <img :src="mtnLogo" alt="" class="vodacom-logo">
                    </div>
                    <button class="justify-self-flex-end logout-button" v-if="userData" v-on:click="onLogoutClicked()">Logout</button>
                </div>
                <router-view class="main-view"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapMutations, mapState} from "vuex";

    export default {
        name: 'App',
        data() {
            return {
              fanclashLogo: this.$store.state.fanclashLogo,
              mtnLogo: this.$store.state.mtnLogo
            }
        },
        computed: {
            ...mapState(['userData'])
        },
        methods: {
            onLogoutClicked() {
                document.cookie = "userData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                this.setUserData(undefined);
                this.$router.push('/');
            },
            ...mapMutations(['setUserData'])
        }
    }
</script>

<style>
    #app {
        overflow: hidden;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 0;
        display: flex;
        height: 100vh;
    }

    .main-view {
        height: 100vh;
        width: 100vw;
        overflow: auto;
        padding-bottom: 10rem;
    }

    .wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100vw;
    }

    .top-bar-container {
        height: 7vw;
        width: 93vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #343a40;
    }

    .vodacom-logo-container {
        width: 30vw;
        height: 7vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fanclash-logo-container {
        width: 7vw;
        height: 7vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #5b6273;
    }

    .fanclash-logo {
        width: 70%;
    }

    .vodacom-logo {
        height: 70%;
    }

    .logout-button {
        padding: 0.5rem 2rem;
        background-color: #FFD500FF;
        border: none;
        border-radius: 5px !important;
        position: absolute;
        right: 2rem;
    }
</style>
