<template>
    <div class="container-fluid">
        <div class="flex-column">
            <div class="row">
                <div class="col-3 d-flex justify-content-end">
                    <h1 class="m-3">Overview</h1>
                </div>
                <div class="col-2 d-flex justify-content-end align-items-center">
                  Region:&nbsp;
                  <select id="region">
                    <option selected>Congo</option>
                  </select>
                </div>
                <div class="d-flex col-7">
                    <div class="row justify-content-around align-items-center">
                        <date-picker placeholder="Select date" v-model="date" value-type="YYYY-MM-DD" class="mr-2"
                                     range></date-picker>
                        <button class="button rounded shadow"
                                v-on:click="onDateRangeConfirmed()">
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <h1>Daily Subscribers</h1>
            </div>
            <div class="row justify-content-center">
                <canvas :width="windowWidth" :height="windowHeight" id="myChart"></canvas>
            </div>
            <div class="row no-gutters justify-content-center container-fluid mt-3">
                <table class="table border">
                    <tr>
                        <th>Date
                            <BIconChevronDown class="chev-down"/>
                        </th>
                        <th>Opening Base</th>
                        <th>Closing Base</th>
                        <th>Daily Net</th>
                        <th>Theoretical Billable Amount</th>
                        <th>Successfully Billed Amount</th>
                    </tr>
                    <tr v-for="(day, index) in userData" :key=index>
                        <td>{{ day.date }}</td>
                        <td>{{ day.openingBase }}</td>
                        <td>{{ day.closingBase }}</td>
                        <td>{{ day.dailyNet }}</td>
                        <td>{{ currencyFormatter.format(day.theoreticalBilling) }}</td>
                        <td>{{ currencyFormatter.format(day.successfullBilling) }}</td>
                    </tr>
                    <tr>
                        <td><b>Total :</b></td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>{{ this.addTableColumnValuesReducer(totalSumData.dailyNet)}}</td>
                        <td>{{
                            currencyFormatter.format(this.addTableColumnValuesReducer(totalSumData.theoreticalBilling))
                            }}
                        </td>
                        <td>{{
                            currencyFormatter.format(this.addTableColumnValuesReducer(totalSumData.successfullBilling))
                            }}
                        </td>
                    </tr>
                </table>
                <button class="button rounded shadow" v-on:click="exportTableToCsv">Export to CSV</button>
            </div>
        </div>
    </div>
</template>

<script>
    import {DashboardDataService} from '../services/dashboardDataService'
    import moment from 'moment'
    import {BIconChevronDown} from 'bootstrap-vue'
    import Chart from 'chart.js'
    import {ExportToCsv} from 'export-to-csv'

    export default {
        name: "DailySubscribers",
        components: {
            BIconChevronDown,
        },
        data() {
            return {
                currencyFormatter: Intl.NumberFormat('af', {
                    style: 'currency',
                    currency: 'CDF',
                    currencyDisplay: 'symbol',
                    minimumFractionDigits: 2
                }),
                windowHeight: window.innerHeight * 0.5,
                windowWidth: window.innerWidth * 0.9,
                date: [],
                dailySubscribersChart: undefined,
                axisNames: ['Total new subscribers', 'Total unsubscribers'],
                daysInRange: [],
                userData: [],
                graphData: {
                    totalUnsubscribers: [],
                    totalSubscribers: []
                },
                totalSumData: {
                    dailyNet: [],
                    theoreticalBilling: [],
                    successfullBilling: []
                }
            }
        },
        methods: {
            exportTableToCsv() {
                const options = {
                    fieldSeparator: ',',
                    quoteStrings: '"',
                    decimalSeparator: '.',
                    useTextFile: false,
                    showLabels: true,
                    showTitle: false,
                    useBom: true,
                    headers: ['Date', 'Opening base', 'Closing base', 'Daily net', 'Theoretical billed amount', 'Successfully billed amount', 'Total subscribed users', 'Total unsubscribed users']
                };
                const csvExporter = new ExportToCsv(options);
                csvExporter.generateCsv(this.userData);
            },

            async onDateRangeConfirmed() {
                if (!this.date[0] || !this.date[1]) return;
                this.$store.state.isLoading = true;
                this.resetDailySubscriberData();
                const result = await DashboardDataService.dailySubscribers(this.date[0], this.date[1]);
                if (result.data.length > 1) {
                    await result.data.forEach((day) => {
                        var dayOfData = {};
                        dayOfData.date = moment(day.Date).format(' YYYY/MM/DD ');
                        dayOfData.openingBase = day.OpeningBase;
                        dayOfData.closingBase = day.ClosingBase;
                        dayOfData.dailyNet = day.DailyNet;
                        dayOfData.theoreticalBilling = day.TheorecticalBillableAmount;
                        dayOfData.successfullBilling = day.SuccessfullyBilledAmount;
                        dayOfData.totalSubscribers = day.TotalSubscribedUsers;
                        dayOfData.totalUnsubscribers = day.TotalCancelledUsers;
                        const daysInRange = new Date(day.Date).getDate() >= 10 ? moment(day.Date).format('MM/DD') : moment(day.Date).format('MM/D');
                        this.daysInRange.push(daysInRange);
                        this.userData.push(dayOfData);
                    });
                    this.orderGraphDatesAscending();
                    this.createGraphDataArray();
                    this.orderGraphDataAscending();
                    this.createDataTotalArrays();
                } else {
                    alert('The selected date range does not contain any data, please select another range')
                }
                this.updateDailySubscribersGraph();
                this.$store.state.isLoading = false;
            },

            createDataTotalArrays() {
                this.userData.forEach((day) => {
                    this.totalSumData.dailyNet.push(day.dailyNet);
                    this.totalSumData.theoreticalBilling.push(day.theoreticalBilling);
                    this.totalSumData.successfullBilling.push(day.successfullBilling);
                })
            },

            resetDailySubscriberData() {
                this.daysInRange = [];
                this.userData = [];
                this.graphData.totalUnsubscribers = [];
                this.graphData.totalSubscribers = [];
                this.totalSumData.successfullBilling = [];
                this.totalSumData.theoreticalBilling = [];
                this.totalSumData.dailyNet = [];
            },
            orderGraphDatesAscending() {
                this.daysInRange.reverse();
            },
            orderGraphDataAscending() {
                this.graphData.totalSubscribers.reverse();
                this.graphData.totalUnsubscribers.reverse();
            },
            updateDailySubscribersGraph() {
                if (this.dailySubscribersChart) this.updateDailySubscribersGraphData();
                else this.getNewDailySubscribersChart();
            },
            addTableColumnValuesReducer(array) {
                const sumValue = array.reduce((a, b) => {
                    return a + b;
                }, 0);
                return sumValue
            },
            getNewDailySubscribersChart() {
                const dailySubscriberCanvas = document.getElementById('myChart');
                const dailySubscriberContext = dailySubscriberCanvas.getContext('2d');
                this.dailySubscribersChart = new Chart(dailySubscriberContext, {
                    type: 'bar',
                    data: {
                        labels: this.daysInRange,
                        datasets: [
                            {
                                label: 'Total Subscribers',
                                backgroundColor: '#FFD500FF',
                                data: this.graphData.totalSubscribers
                            },
                            {
                                label: 'Total unsubscribers',
                                backgroundColor: '#9B9B9B',
                                data: this.graphData.totalUnsubscribers
                            },
                        ],
                    },
                    options: {
                        scales: {
                            xAxes: [{
                                stacked: true
                            }],
                            yAxes: [{
                                stacked: true
                            }]
                        }
                    }
                });
            },
            updateDailySubscribersGraphData() {
                this.dailySubscribersChart.data.labels = this.daysInRange;
                this.dailySubscribersChart.data.datasets[0].data = this.graphData.totalSubscribers;
                this.dailySubscribersChart.data.datasets[1].data = this.graphData.totalUnsubscribers;
                this.dailySubscribersChart.update();
            },
            createGraphDataArray() {
                this.userData.forEach((day) => {
                    this.graphData.totalSubscribers.push(day.totalSubscribers);
                    this.graphData.totalUnsubscribers.push(day.totalUnsubscribers);
                })
            },
        },
        mounted() {
            const dateTo = moment(new Date()).format('YYYY-MM-DD');
            const dateFrom = moment(Date.now() - 7 * 24 * 3600 * 1000).format('YYYY-MM-DD');
            this.date.push(dateFrom);
            this.date.push(dateTo);
            this.onDateRangeConfirmed();
        }
    }
</script>

<style scoped>
    .button {
        background-color: #FFD500FF;
        width: 150px;
        margin: 4px;
    }

    .chev-down {
        color: red;
    }

    select {
      width: 100%;
      height: 30px;
    }
</style>
