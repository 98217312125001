import axios from 'axios';
import {config} from '../config';
// const fetch = require("node-fetch");

export class DashboardDataService {
    static async dailySubscribers(fromDate, toDate) {
        return await axios.post(`${config.dashboardServiceUri}/daily-subscribers-mtn`, {fromDate, toDate});
    }

    static async userActivity(fromDate, toDate) {
        return await axios.post(`${config.dashboardServiceUri}/user-activity-mtn`, {fromDate, toDate});
    }

    static async campaignTargetUsers(activeFromDate, game, channel) {
        return await axios.post(`${config.dashboardServiceUri}/get-target-users`, {activeFromDate, game, channel});
    }

    static async sendCampaignTextAuth(email, msisdn, campaignText) {
        return await axios.post(`${config.dashboardServiceUri}/campaign-text-send-auth`, {email, msisdn, campaignText});
    }

    static async sendCampaignText(otp, msisdn, gameFilter, activeUsersDate, channelFilter, campaignText) {
        return await axios.post(`${config.dashboardServiceUri}/send-campaign-text`, {otp, msisdn, gameFilter, activeUsersDate, channelFilter, campaignText});
    }
}
