<template>
    <div class="container">
        <h1 class="row justify-content-center mt-3">Build Campaign - 2</h1>
        <p class="row justify-content-center">You are sending a message to:<b class="px-2">{{totalTargetUsers}}
            Users</b></p>
        <div class="row justify-content-center">
            <textarea v-model="textMessage" name="messageBuilder" id="messageBuilder"
                      class="message-container rounded shadow" cols="30" rows="10"
                      placeholder="Type text message here"
                      :disabled="pageState.otpSent || pageState.campaignCompletion"></textarea>
        </div>
        <div class="row justify-content-center mt-2" v-if="pageState.createMessage">
            <button class="button rounded shadow" v-on:click="onTextSubmit">Submit</button>
        </div>
        <div class="row justify-content-center mt-2" v-if="pageState.campaignTextAuthError">
            <p class="text-red">An error has occured while trying to create your campaign text messsage please try
                again!</p>
        </div>
        <div class="row justify-content-center mt-3" v-if="pageState.otpSent">
            <p class="mb-0">We have sent you a sample campaign text, as well as an OTP. If you are satisfied with the
                sample text,
                please enter the OTP and click Confirm & Send.</p>
        </div>
        <div class="row justify-content-center mt-2" v-if="pageState.invalidOtp">
            <p class="mb-0 text-red">The OTP entered was invalid, please try again.</p>
        </div>
        <div class="row justify-content-center mt-3" v-if="pageState.otpSent || pageState.invalidOtp">
            <input class="otp-input rounded shadow text-center" v-model="otp" placeholder="Enter OTP" autofocus/>
            <button class="button rounded shadow" v-on:click="onOtpSubmit">Confirm & Send</button>
        </div>
        <div class="row justify-content-center mt-2" v-if="pageState.otpSent || pageState.invalidOtp">
            <button class="button-long rounded shadow" v-on:click="onEditMessageClicked">Edit Campaign Text</button>
        </div>
        <div class="row justify-content-center mt-2" v-if="pageState.otpSent || pageState.invalidOtp">
            <p>Haven't received an OTP yet? Click <a v-on:click="onOtpResendClicked" class="text-link">resend</a> and we
                will send you another one.</p>
        </div>
        <div class="row justify-content-center mt-3" v-if="pageState.campaignCompletion">
            <p>Campaign text successfully sent!</p>
        </div>
        <div class="row justify-content-center mt-2" v-if="pageState.campaignCompletion">
            <button class="button-long rounded shadow" v-on:click="onBackClicked">Build new campaign</button>
        </div>
    </div>
</template>

<script>
    import {DashboardDataService} from "../services/dashboardDataService";
    // import {testNumbers} from "../config/testNumberMappping";

    export default {
        name: "CampaignAuth",
        data() {
            return {
                totalTargetUsers: this.$store.state.targetedUsers.length,
                otp: '',
                textMessage: '',
                pageState: {
                    invalidOtp: false,
                    campaignTextAuthError: false,
                    createMessage: true,
                    otpSent: false,
                    otpAuth: false,
                    campaignCompletion: false
                }
            }
        },
        methods: {
            async onTextSubmit() {
                const currentUser = this.$store.state.userData;
                const response = await DashboardDataService.sendCampaignTextAuth(currentUser.LoginName, currentUser.Msisdn, this.textMessage);

                if (response.data.result === 'Success') {
                    this.setPageState('otpSent');
                } else {
                    this.setPageState('campaignTextAuthError');
                }
            },

            async onOtpResendClicked() {
                const currentUser = this.$store.state.userData;
                await DashboardDataService.sendCampaignTextAuth(currentUser.LoginName, currentUser.Msisdn, this.textMessage);
                this.setPageState('otpSent');
            },

            async onOtpSubmit() {
                const currentUser = this.$store.state.userData;
                // const testTargetUsers = this.$store.state.targetedUsers;
                const response = await DashboardDataService.sendCampaignText(
                    this.otp,
                    currentUser.Msisdn,
                    this.$store.state.gameFilter,
                    this.$store.state.activeUsersDate,
                    this.$store.state.channelFilter,
                    this.textMessage);

                if (response.data.result === "Success") {
                    this.setPageState('campaignCompletion');
                } else if (response.data.result === "Invalid OTP") {
                    this.setPageState('invalidOtp');
                    this.pageState.otpSent = true;
                } else {
                    this.setPageState('campaignTextAuthError');
                }
            },

            // mapTestNumbersInTargetUsersArray() {
            //   const returnedTestTargetNumbers = [];
            //   testNumbers.forEach((testNumber) => {
            //       if (this.$store.state.targetedUsers.indexOf(testNumber) > 0) returnedTestTargetNumbers.push(testNumber);
            //   });

            //   return returnedTestTargetNumbers;
            // },

            onEditMessageClicked() {
                this.otp = '';
                this.textMessage = '';
                this.setPageState('createMessage');
            },

            onBackClicked() {
                this.otp = '';
                this.textMessage = '';
                this.setPageState('createMessage');
                this.$store.state.targetedUsers = [];
                this.$router.push('/dashboard/campaign-message-builder/campaign-target-users');
            },

            setPageState(activeState) {
                const states = Object.keys(this.pageState);
                states.forEach((state) => {
                  this.pageState[state] = activeState === state;
                })
            }
        }

    }
</script>

<style scoped>
    .message-container {
        border: solid 2px gray;
        width: 50vw;
        height: 20vh;
        margin-top: 10px;
        text-align: center;
    }

    .button {
        background-color: #FFD500FF;
        width: 150px;
        margin: 4px;
    }

    .button-long {
        background-color: #FFD500FF;
        width: 175px;
        margin: 4px;
    }

    .otp-input {
        background-color: white;
        width: 150px;
        margin: 4px;
    }

    .text-link {
        color: red;
    }

    .text-red {
        color: red;
    }

    .text-link:hover {
        color: #212527;
        cursor: pointer;
    }
</style>
