import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {routes} from "./router/routes";
import store from './store';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VTooltip from 'v-tooltip'

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(DatePicker);
Vue.use(VTooltip);
Vue.config.productionTip = false;
const router = new VueRouter({
  mode: 'history',
  hash: false,
  routes,
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
