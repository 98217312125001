import DailySubscribers from "../components/DailySubscribers";
import Support from "../components/Support";
import App from "../App";
import Login from "../components/Login";
import Dashboard from "../views/Dashboard";
import setDashboardIconsState from "../functions/routerGaurds/setDashboardIcons";
import CampaignTargetUsers from "../components/CampaignTargetUsers";
import Users from "../components/UserActivity";
import loginGuard from "../functions/routerGaurds/authenticate";
import CampaignAuth from "../components/CampaignAuth";
import CampaignMessageBuilder from "../components/CampaignMessageBuilder";

export const routes = [
    {
        path: '*',
        redirect: '/',
    },
    {
        path: '/',
        redirect: '/login',
        component: App,
    },
    {
        path: '/login',
        component: Login,
        name: Login,
    },
    {
        path: '/dashboard',
        component: Dashboard,
        beforeEnter: (to, from, next) => {
            loginGuard(to, from, next);
            next()
        },
        children: [
            {
                path: 'subscriber-overview',
                component: DailySubscribers,
                name: DailySubscribers,
                beforeEnter: (to, from, next) => {
                    setDashboardIconsState(to.path);
                    next()
                }
            },
            {
                path: 'support',
                component: Support,
                name: Support,
                beforeEnter: (to, from, next) => {
                    setDashboardIconsState(to.path);
                    next()
                }
            },
            {
                path: 'users',
                component: Users,
                name: Users,
                beforeEnter: (to, from, next) => {
                    setDashboardIconsState(to.path);
                    next()
                }
            },
            {
                path: 'campaign-message-builder',
                component: CampaignMessageBuilder,
                name: CampaignMessageBuilder,
                beforeEnter: (to, from, next) => {
                    setDashboardIconsState('/dashboard/campaign-message-builder');
                    next()
                },
                children: [
                    {
                        path: 'campaign-target-users',
                        component: CampaignTargetUsers,
                        name: CampaignTargetUsers
                    },
                    {
                        path: 'campaign-message-auth',
                        component: CampaignAuth,
                        name: CampaignAuth
                    }
                ]
            },
        ],
    },
];
