<template>
    <div class="container-fluid">
        <div class="row">
            <div class="container-fluid">
                <h1 class="text-center">Build campaign - 1</h1>
                <p class="text-center">Pick your user group:</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 d-flex justify-content-center">
                <div class="campaign-table">
                    <h3 class="text-center">Days Active</h3>
                    <div class="table-row-item"><label class="custom-radio-btn"><input type="radio"
                                                                                       name="daysActive"
                                                                                       v-model="activeUsersValue"
                                                                                       @change="setTargetUsersFilter(activeUsersValue, gameValue, channelValue)"
                                                                                       value="All"
                    /><span
                            class="checkmark"></span></label>
                        <p class="campaign-text">All</p></div>
                    <div class="table-row-item"><label class="custom-radio-btn"><input type="radio"
                                                                                       name="daysActive"
                                                                                       v-model="activeUsersValue"
                                                                                       @change="setTargetUsersFilter(activeUsersValue, gameValue, channelValue)"
                                                                                       value="7"
                     /><span
                            class="checkmark"></span></label>
                        <p class="campaign-text">7 day - Active</p></div>
                    <div class="table-row-item"><label class="custom-radio-btn"><input type="radio"
                                                                                       name="daysActive"
                                                                                       v-model="activeUsersValue"
                                                                                       @change="setTargetUsersFilter(activeUsersValue, gameValue, channelValue)"
                                                                                       value="30"
                     /><span
                            class="checkmark"></span></label>
                        <p class="campaign-text">30 day - Active</p></div>
                    <div class="table-row-item"><label class="custom-radio-btn"><input type="radio"
                                                                                       name="daysActive"
                                                                                       v-model="activeUsersValue"
                                                                                       @change="setTargetUsersFilter(activeUsersValue, gameValue, channelValue)"
                                                                                       value="60"
                     /><span
                            class="checkmark"></span></label>
                        <p class="campaign-text">60 day - Active</p></div>
                </div>
            </div>
            <div class="col-12 col-md-4 d-flex justify-content-center">
                <div class="campaign-table">
                    <h3 class="text-center">Game</h3>
                    <div class="table-row-item"><label class="custom-radio-btn"><input type="radio"
                                                                                       name="game"
                                                                                       v-model="gameValue"
                                                                                       @change="setTargetUsersFilter(activeUsersValue, gameValue, channelValue)"
                                                                                       value="All"
                    /><span
                            class="checkmark"></span></label>
                        <p class="campaign-text">All</p></div>
                    <div class="table-row-item"><label class="custom-radio-btn"><input type="radio"
                                                                                       name="game"
                                                                                       v-model="gameValue"
                                                                                       @change="setTargetUsersFilter(activeUsersValue, gameValue, channelValue)"
                                                                                       value="FantasySoccer"
                     /><span
                            class="checkmark"></span></label>
                        <p class="campaign-text">VCFL - Soccer</p></div>
                    <div class="table-row-item"><label class="custom-radio-btn"><input type="radio"
                                                                                       name="game"
                                                                                       v-model="gameValue"
                                                                                       @change="setTargetUsersFilter(activeUsersValue, gameValue, channelValue)"
                                                                                       value="FantasyRugby"
                     /><span
                            class="checkmark"></span></label>
                        <p class="campaign-text">VCFL - Rugby</p></div>
                    <div class="table-row-item"><label class="custom-radio-btn"><input type="radio"
                                                                                       name="game"
                                                                                       v-model="gameValue"
                                                                                       @change="setTargetUsersFilter(activeUsersValue, gameValue, channelValue)"
                                                                                       value="SoccerZone"
                     /><span
                            class="checkmark"></span></label>
                        <p class="campaign-text">VCFL - Soccer News</p></div>
                    <div class="table-row-item"><label class="custom-radio-btn"><input type="radio"
                                                                                       name="game"
                                                                                       v-model="gameValue"
                                                                                       @change="setTargetUsersFilter(activeUsersValue, gameValue, channelValue)"
                                                                                       value="RugbyZone"
                     /><span
                            class="checkmark"></span></label>
                        <p class="campaign-text">VCFL - Rugby News</p></div>
                </div>
            </div>
            <div class="col-12 col-md-4 d-flex justify-content-center">
                <div class="campaign-table">
                    <h3 class="text-center">Channel</h3>
                    <div class="table-row-item"><label class="custom-radio-btn"><input type="radio"
                                                                                       name="channel"
                                                                                       v-model="channelValue"
                                                                                       @change="setTargetUsersFilter(activeUsersValue, gameValue, channelValue)"
                                                                                       value="All"
                    ><span
                            class="checkmark"></span></label>
                        <p class="campaign-text">All</p></div>
                    <div class="table-row-item"><label class="custom-radio-btn"><input type="radio"
                                                                                       name="channel"
                                                                                       v-model="channelValue"
                                                                                       @change="setTargetUsersFilter(activeUsersValue, gameValue, channelValue)"
                                                                                       value="Fanclash_Web"
                    ><span
                            class="checkmark"></span></label>
                        <p class="campaign-text">Web Users</p></div>
                    <div class="table-row-item"><label class="custom-radio-btn"><input type="radio"
                                                                                       name="channel"
                                                                                       v-model="channelValue"
                                                                                       @change="setTargetUsersFilter(activeUsersValue, gameValue, channelValue)"
                                                                                       value="Fanclash_USSD"
                    ><span
                            class="checkmark"></span></label>
                        <p class="campaign-text">USSD Users</p></div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center p-3 m-3">
            <div class="total-users-box rounded shadow">
                <div class="d-flex justify-content-around">
                        <span class="total-users-text">
                    Total Users:
                    </span>
                    <span>
                            {{totalUsers}}
                        </span>
                </div>
            </div>
            <button class="button rounded shadow"
                    v-on:click="onConfirm">
                Confirm
            </button>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import {DashboardDataService} from "../services/dashboardDataService";

    export default {
        name: "CampaignTargetUsers",
        data() {
            return {
                gameValue: 'All',
                activeUsersValue: '7',
                totalUsers: '',
                channelValue: 'All'
            }
        },
        mounted() {
            this.setTargetUsersFilter(this.activeUsersValue, this.gameValue, this.channelValue)
        },
        methods: {
            onConfirm() {
                this.$router.push('/dashboard/campaign-message-builder/campaign-message-auth')
            },
            async setTargetUsersFilter(days, game, channel) {
                if (days && game && channel) {
                    if (days === "All") days = null;
                    if (game === "All") game = null;
                    if (channel === "All") channel = null;
                    this.$store.state.isLoading = true;
                    const currentDate = new Date();
                    const date = currentDate.setDate(currentDate.getDate() - days);
                    const formattedDate = days === null ? null : moment(date).format(' YYYY/MM/DD ');
                    const targetedUsers = await DashboardDataService.campaignTargetUsers(formattedDate, game, channel);
                    this.$store.state.gameFilter = game;
                    this.$store.state.activeUsersDate = formattedDate;
                    this.$store.state.channelFilter = channel;
                    this.totalUsers = targetedUsers.data.length;
                    this.$store.state.targetedUsers = targetedUsers.data;
                    this.$store.state.isLoading = false;
                }
            },
        }
    }

</script>

<style scoped>
    .campaign-table {
        border: solid black 3px;
        border-radius: 3%;
        width: 60%;
        height: 50vh;
        padding: 5px;
    }

    .table-row-item {
        display: flex;

        padding: 5px;
    }

    .campaign-text {
        margin-left: 10px;
    }

    .button {
        background-color: #FFD500FF;
        width: 250px;
        margin: 8px;
    }

    .total-users-box {
        border: solid gray 2px;
        width: 250px;
        margin: 8px;
    }

    .total-users-text {
        color: #FFD500FF;
    }


    .custom-radio-btn {
        width: 25px;
        height: 25px;
        border: 2px solid #444;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom-radio-btn .checkmark {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border-radius: 50%;
        background-color: #FFD500FF;
        display: none;
    }

    .custom-radio-btn input {
        display: none;
    }

    .custom-radio-btn input:checked + .checkmark {
        display: inline-block;
    }
</style>
