<template>
    <router-view class="main-view"></router-view>
</template>

<script>
    export default {
        name: "CampaignMessageBuilder"
    }
</script>

<style scoped>

</style>
