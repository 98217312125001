import {dashBoardRoutes} from "../../config";
import store from '../../store/index'

export default function setDashboardIconsState(route) {
    dashBoardRoutes.forEach((dashBoardRoute) => {
        if (route === dashBoardRoute.path) {
            store.state.dashboardIcons[`${dashBoardRoute.iconName}`] = require(`../../assets/icons/${dashBoardRoute.iconName}_Active.png`);
        } else {
            store.state.dashboardIcons[`${dashBoardRoute.iconName}`] = require(`../../assets/icons/${dashBoardRoute.iconName}_Inactive.png`);
        }
    })
}
