<template>
    <div class="container">
        <div class="row justify-content-center pt-5 mt-5">
            <h1><b>Welcome</b></h1>
        </div>
        <div class="row justify-content-center">
            <p>to the home of MTN Fantasy Soccer!</p>
        </div>
        <div class="justify-content-center" v-if="loginStates.emailInput">
            <div class="row justify-content-center pb-2 mb-2">
                <input placeholder="Enter email" type="text" class="text-center w-25 rounded shadow" v-on:keyup.enter="onEmailSubmitted"
                       v-model="loginModel.email" autofocus/>
            </div>
            <div class="row justify-content-center">
                <button class="w-25 submit-button rounded shadow" v-on:click="onEmailSubmitted">Submit</button>
            </div>
            <div v-if="loginResponseMessage" class="row error justify-content-center">
                <span class="error">{{loginResponseMessage}}</span>
            </div>
        </div>
        <div class="justify-content-center" v-if="loginStates.passwordCreation">
            <div class="row justify-content-center pb-2 mb-2">
                <input placeholder="Please enter new password..." class="text-center w-25 rounded shadow" type="password" v-model="loginModel.passwordCreate1">
            </div>
            <div class="row justify-content-center pb-2 mb-2">
                <input placeholder="Repeat password" class="text-center w-25 rounded shadow" type="password" v-on:keyup.enter="onPasswordCreateSubmitted" v-model="loginModel.passwordCreate2" autofocus>
            </div>
            <div class="row justify-content-center">
                <button class="w-25 submit-button rounded shadow" v-on:click="onPasswordCreateSubmitted">Submit</button>
            </div>
            <div v-if="loginResponseMessage" class="row error justify-content-center mt-2">
                <span class="error">{{loginResponseMessage}}</span>
            </div>
        </div>
        <div class="justify-content-center" v-if="loginStates.passwordInput">
            <div class="row justify-content-center pb-2 mb-2">
                <input placeholder="Enter password" type="password" class="text-center w-25 rounded shadow" v-on:keyup.enter="onPasswordSubmitted"
                       v-model="loginModel.password" autofocus/>
            </div>
            <div class="row justify-content-center">
                <button class="w-25 submit-button rounded shadow" v-on:click="onPasswordSubmitted">Submit</button>
            </div>
            <div v-if="loginResponseMessage" class="row error justify-content-center">
                <span class="error">{{loginResponseMessage}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapMutations, mapState} from 'vuex';
    import {LoginService} from "../services/login.service";

    export default {
        name: 'Login',
        data() {
            return {
                loginStates: {
                    emailInput: true,
                    passwordInput: false,
                    passwordCreation: false
                },
                loginModel: {
                    email: '',
                    password: '',
                    firstname: '',
                    surname: '',
                    region: '',
                    passwordCreate1: '',
                    passwordCreate2: '',
                },
                loginResponseMessage: ''
            }
        },
        computed: {
            ...mapState(['userData'])
        },
        methods: {
            async setLoginState(stateName) {
                for (let state in this.loginStates) {
                    if (this.loginStates[state] && stateName === state) return;
                    this.loginStates[state] = state === stateName;
                }
            },
            onEmailSubmitted() {
                LoginService.login(this.loginModel, this.loginResponse.bind(this));
            },
            onPasswordSubmitted() {
                LoginService.login(this.loginModel, this.loginResponse.bind(this));
            },
            onPasswordCreateSubmitted() {
                if (this.loginModel.passwordCreate1.length < 5) {
                    this.loginResponseMessage = 'Password must be at least 5 characters long.';
                } else if (/\s/.test(this.loginModel.passwordCreate1)) {
                    this.loginResponseMessage = 'Password can not contain spaces.';
                } else if (this.loginModel.passwordCreate1 !== this.loginModel.passwordCreate2) {
                    this.loginResponseMessage = 'Passwords must match.';
                } else {
                    this.loginModel.password = this.loginModel.passwordCreate1;
                    LoginService.createUser(this.loginModel, this.loginResponse.bind(this));
                }
            },
            loginResponse(response) {
                this.loginResponseMessage = '';
                switch (response.data.result) {
                    case 'Password Input Required': {
                        this.setLoginState('passwordInput');
                        break;
                    }
                    case 'Password Creation Required': {
                        this.loginResponseMessage = 'Please create a password for this admin account';
                        this.setLoginState('passwordCreation');
                        break;
                    }
                    case 'Incorrect Password': {
                        this.loginResponseMessage = 'Incorrect password';
                        break;
                    }
                    case 'No User Found': {
                        this.loginResponseMessage = 'Invalid/incorrect email address';
                        break;
                    }
                    case 'Password created succesfully': {
                        const {LoginName, Password, Msisdn} = response.data.result;
                        const userData = {LoginName, Password, Msisdn};
                        this.setUserData(userData);
                        document.cookie = ('userData=' + JSON.stringify(userData));
                        this.setIsAuthenticated(true);
                        this.$router.push('/dashboard/subscriber-overview');
                        break;
                    }
                    default: {
                        const {LoginName, Password, Msisdn} = response.data.result;
                        const userData = {LoginName, Password, Msisdn};
                        this.setUserData(userData);
                        document.cookie = ('userData=' + JSON.stringify(userData));
                        this.setIsAuthenticated(true);
                        this.$router.push('/dashboard/subscriber-overview');
                        break;
                    }
                }
            },
            ...mapMutations(['setUserData', 'setIsAuthenticated'])
        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .container {
        flex-wrap: nowrap !important;
    }

    .submit-button {
        background-color: #FFD500FF;
        border: none;
        border-radius: 5px !important;
    }
</style>
