import axios from 'axios';
import {config} from '../config';
// const fetch = require("node-fetch");

export class LoginService {
    static login(loginModel, callback) {
        const {email, password} = loginModel;
        axios.post(`${ config.dashboardServiceUri}/admin-login`, {email, password})
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                if (error.status === 500) callback(undefined, error.status);
                console.error(error);
            });
    }

    static createUser(loginModel, callback) {
        const {email, password} = loginModel;
        axios.post(`${config.dashboardServiceUri}/create-password`, {email, password})
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                if (error.status === 500) callback(undefined, error.status);
                // console.error(error);
            });
    }
}
