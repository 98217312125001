<template>
  <div class="container-fluid">
    <div class="flex-column">
      <div class="row">
        <div class="col-5 d-flex justify-content-end">
          <h1 class="m-3">Overview</h1>
        </div>
        <div class="d-flex col-7">
          <div class="row justify-content-around align-items-center">
            <date-picker placeholder="Select date" v-model="date" value-type="YYYY-MM-DD" class="mr-2"
                         range></date-picker>
            <button class="button rounded shadow"
                    v-on:click="onDateRangeConfirmed()">
              Confirm
            </button>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <h1>User Activity</h1>
      </div>
      <div class="row justify-content-center">
        <canvas :width="windowWidth" :height="windowHeight" id="myChart"></canvas>
      </div>
      <div class="row no-gutters justify-content-center container-fluid mt-3">
        <table class="table border">
          <tr>
            <th>Date
              <BIconChevronDown class="chev-down"/>
            </th>
            <th>Total Actives</th>
            <th>Unique Users Engaged</th>
            <th>USSD Engaged</th>
            <th>Web Engaged</th>
            <th>Total Entries</th>
          </tr>
          <tr v-for="(day, index) in usersData" :key="index">
            <td>{{ day.date }}</td>
            <td>{{ day.totalSubscribedUsers }}</td>
            <td>{{ day.uniqueEngaged }}</td>
            <td>{{ day.ussdEngaged }}</td>
            <td>{{ day.webEngaged }}</td>
            <td>{{ day.totalEntries }}</td>
          </tr>
          <tr>
            <td><b>Total :</b></td>
            <td>N/A</td>
            <td> {{ dataTotals.totatalUniqueEngagedUsers }}</td>
            <td>{{ this.addTableColumnValuesReducer(dataTotals.totalUssdArray) }}</td>
            <td>{{ this.addTableColumnValuesReducer(dataTotals.totalWebArray) }}</td>
            <td>{{ this.addTableColumnValuesReducer(dataTotals.totalEntries) }}</td>
          </tr>
        </table>
        <button class="button rounded shadow" v-on:click="exportTableToCsv">Export to CSV</button>
      </div>
    </div>
  </div>
</template>

<script>
import {DashboardDataService} from '../services/dashboardDataService'
import moment from 'moment'
import {BIconChevronDown} from 'bootstrap-vue'
import Chart from "chart.js";
import {ExportToCsv} from 'export-to-csv'

export default {
  name: "UserActivity",
  components: {
    BIconChevronDown
  },
  data() {
    return {
      dailyUsersChart: undefined,
      windowHeight: window.innerHeight * 0.5,
      windowWidth: window.innerWidth * 0.9,
      date: [],
      axisNames: ['Total Engaged Users', 'Total USSD Engaged', 'Total Web Engaged', 'Total Unique Users'],
      usersData: [],
      daysInRange: [],
      graphData: {
        totalUsersEngaged: [],
        ussdEngaged: [],
        webEngaged: [],
        uniqueEngaged: []
      },
      dataTotals: {
        totatalUniqueEngagedUsers: '',
        totalUssdArray: [],
        totalWebArray: [],
        totalEntries: []
      },
    }
  },
  methods: {
    exportTableToCsv() {
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        useTextFile: false,
        showLabels: true,
        showTitle: false,
        useBom: true,
        headers: ['Date', 'Total actives', 'Unique users engaged', 'Ussd engaged', 'Web engaged']
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(this.usersData);
    },

    async onDateRangeConfirmed() {
      if (!this.date[0] || !this.date[1]) return;
      this.$store.state.isLoading = true;
      this.resetUserActivityData();
      const result = await DashboardDataService.userActivity(this.date[0], this.date[1]);
      const secondRecord = result.data.recordsets[1];
      this.dataTotals.totatalUniqueEngagedUsers = secondRecord[0].TotalUniqueUsers;
      if (result.data.recordset) {
        await result.data.recordset.forEach((day) => {
          var dayOfData = {};
          dayOfData.totalSubscribedUsers = day.NumberOfUsers;
          dayOfData.totalUsersEngaged = day.USSDEngaged + day.WebEngaged;
          dayOfData.ussdEngaged = day.USSDEngaged;
          dayOfData.webEngaged = day.WebEngaged;
          dayOfData.uniqueEngaged = day.UniqueEngaged;
          dayOfData.totalEntries = day.Entries ? day.Entries : 0;
          dayOfData.date = moment(day.Date).format(' YYYY/MM/DD ');
          const daysInRange = new Date(day.Date).getDate() >= 10 ? moment(day.Date).format('MM/DD') : moment(day.Date).format('MM/D');
          this.daysInRange.push(daysInRange);
          this.usersData.push(dayOfData)
        });
        this.setDataTotalArrays();
        this.orderGraphDatesAscending();
        this.createGraphDataArray();
        this.orderGraphDataAscending();
      } else {
        alert('The selected date range does not contain any data, please select another range')
      }
      this.updateDailyUsersGraph();
      this.$store.state.isLoading = false;
    },

    setDataTotalArrays() {
      this.usersData.forEach((day) => {
        this.dataTotals.totalWebArray.push(day.webEngaged);
        this.dataTotals.totalUssdArray.push(day.ussdEngaged);
        this.dataTotals.totalEntries.push(day.totalEntries);

      })
    },

    createGraphDataArray() {
      this.usersData.forEach((day) => {
        this.graphData.totalUsersEngaged.push(day.totalUsersEngaged);
        this.graphData.ussdEngaged.push(day.ussdEngaged);
        this.graphData.webEngaged.push(day.webEngaged);
        this.graphData.uniqueEngaged.push(day.uniqueEngaged);
      })
    },

    orderGraphDataAscending() {
      this.graphData.webEngaged.reverse();
      this.graphData.ussdEngaged.reverse();
      this.graphData.uniqueEngaged.reverse();
      this.graphData.totalUsersEngaged.reverse();
    },

    resetUserActivityData() {
      this.usersData = [];
      this.daysInRange = [];
      this.dataTotals.totatalUniqueEngagedUsers = '';
      this.graphData.totalUsersEngaged = [];
      this.graphData.ussdEngaged = [];
      this.graphData.webEngaged = [];
      this.graphData.uniqueEngaged = [];
      this.dataTotals.totalUssdArray = [];
      this.dataTotals.totalWebArray = [];
    },
    orderGraphDatesAscending() {
      this.daysInRange.reverse();
    },
    updateDailyUsersGraph() {
      if (this.dailySubscribersChart) this.updateDailyUsersGraphData();
      else this.getNewDailyUsersChart();
    },
    addTableColumnValuesReducer(array) {
      const sumValue = array.reduce(function (a, b) {
        return a + b;
      }, 0);
      return sumValue
    },
    getNewDailyUsersChart() {
      const dailySubscriberCanvas = document.getElementById('myChart');
      const dailySubscriberContext = dailySubscriberCanvas.getContext('2d');
      this.dailyUsersChart = new Chart(dailySubscriberContext, {
        type: 'line',
        data: {
          labels: this.daysInRange,
          datasets: [
            {
              label: 'Total Engaged Users',
              borderColor: '#FFD500FF',
              fill: false,
              data: this.graphData.totalUsersEngaged
            },
            {
              label: 'Total USSD Engaged',
              borderColor: '#000000',
              fill: false,
              data: this.graphData.ussdEngaged
            },
            {
              label: 'Total Web Engaged',
              borderColor: '#9B9B9B',
              fill: false,
              data: this.graphData.webEngaged
            },
            {
              label: 'Total Unique Users',
              borderColor: '#4f7ee0',
              fill: false,
              data: this.graphData.uniqueEngaged
            },
          ],
        },
      });
    },
    updateDailyUsersGraphData() {
      this.dailyUsersChart.data.labels = this.daysInRange;
      this.dailyUsersChart.data.datasets[0].data = this.graphData.totalEngaged;
      this.dailyUsersChart.data.datasets[1].data = this.graphData.ussdEngaged;
      this.dailyUsersChart.data.datasets[2].data = this.graphData.webEngaged;
      this.dailyUsersChart.data.datasets[3].data = this.graphData.uniqueEngaged;
      this.dailyUsersChart.update();
    }
  },
  mounted() {
    const dateTo = moment(new Date()).format('YYYY-MM-DD');
    const dateFrom = moment(Date.now() - 7 * 24 * 3600 * 1000).format('YYYY-MM-DD');
    this.date.push(dateFrom);
    this.date.push(dateTo);
    this.onDateRangeConfirmed();
  }
}
</script>

<style scoped>
.button {
  background-color: #FFD500FF;
  width: 150px;
  margin: 4px;
}

.chev-down {
  color: red;
}
</style>
