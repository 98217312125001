<template>
    <div>
        <h1>This is the Support Page</h1>
    </div>
</template>

<script>
    export default {
        name: "Support"
    }
</script>

<style scoped>

</style>
