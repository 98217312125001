export const config = {
    // upstreamReportingUri: 'https://penrose-digital-reporting-dashboard-api.azurewebsites.net',
    dashboardServiceUri: 'https://utils.vodacomfantasy.com/vodacom-dashboard',
    // dashboardServiceUri: 'http://localhost:5000'
};
export const dashBoardRoutes = [
    {
      path: '/dashboard/subscriber-overview',
      iconName: 'graphIcon'
    },
    {
        path: '/dashboard/support',
        iconName: 'supportIcon'
    },
    {
        path: '/dashboard/users',
        iconName: 'usersIcon'
    },
    {
        path: '/dashboard/campaign-message-builder',
        iconName: 'messageIcon'
    }
];
