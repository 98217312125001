<template>
    <div class="d-flex height overflow-hidden">
        <b-navbar class="side-bar flex-column no-gutters m-0 p-0" type="dark" variant="dark">
            <div class="nav-item-container" v-on:click="setDashboardRoute('/dashboard/subscriber-overview')" v-b-tooltip="'Daily Subscriber Stats'">
                <img :src="dashboardIcons.graphIcon" alt="" class="nav-item-image">
            </div>
            <div class="nav-item-container" v-on:click="setDashboardRoute('/dashboard/users')" v-b-tooltip="'User Activity Stats'">
                <img :src="dashboardIcons.usersIcon" alt="" class="nav-item-image">
            </div>
            <div class="nav-item-container" v-on:click="setDashboardRoute('/dashboard/campaign-message-builder/campaign-target-users')" v-b-tooltip="'Build An SMS Campaign'">
                <img :src="dashboardIcons.messageIcon" alt="" class="nav-item-image">
            </div>
            <div class="nav-item-container nav-item-disabled" v-b-tooltip="'User Support Page'">
                <img :src="dashboardIcons.supportIcon" alt="" class="nav-item-image">
            </div>
        </b-navbar>
        <div v-if="this.$store.state.isLoading" class="loading-overlay">
            <b-spinner class="loadin-spinner"></b-spinner>
        </div>
        <router-view class="main-view"></router-view>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "Dashboard",
        computed: {
            ...mapState(['dashboardIcons'])
        },
        methods: {
            setDashboardRoute(route) {
                this.$router.push(route);
            }
        }
    }
</script>

<style scoped>
    .loading-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 7vw;
        width: 93vw;
        height: calc(100vh - 7vw);
        position: absolute;
        z-index: 9;
        background-color: gray;
        opacity: 0.5;
    }

    .side-bar {
        width: 7vw;
        height: 90vh;
    }

    .nav-item-image {
        width: 4vw !important;
    }

    .nav-item-container {
        width: 7vw;
        height: 10vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-item-container:hover {
        background-color: #212527;
        cursor: pointer;
    }

    .height {
        height: 90vh;
    }

    .nav-item-disabled:hover {
        cursor: not-allowed;
    }

    .loadin-spinner {
        width: 10vw;
        height: 10vw;
    }
</style>
