import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userData: undefined,
    subscriberOverviewData: undefined,
    mtnLogo: require('../assets/icons/MTN-logo.jpg'),
    fanclashLogo: require('../assets/icons/FanClashLogo.png'),
    dashboardIcons: {
      graphIcon: require('../assets/icons/graphIcon_Inactive.png'),
      usersIcon: require('../assets/icons/usersIcon_Inactive.png'),
      supportIcon: require('../assets/icons/supportIcon_Inactive.png'),
      messageIcon: require('../assets/icons/messageIcon_Inactive.png')
    },
    gameFilter: null,
    activeUsersDate: null,
    channelFilter: null,
    targetedUsers: [],
    isLoading: false,
  },
  mutations: {
    setUserData (state, userData) {
      state.userData = userData;
    },
    setSubscriberData(state, subscriberOverviewData) {
      state.subscriberOverviewData = subscriberOverviewData;
    },
    setIsAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    }
  },
  actions: {
  },
  modules: {
  }
})
